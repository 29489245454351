<template>
  <v-container class="home-container" fluid>
      <div class="mkt animate__fadeInUp">
        <img src="@/assets/hispanic_market.png" alt="Hispanic Market">
        <div>
          <router-link to="/hispanic-market"><button class="btn-market">Click Here!</button></router-link>
        </div>
      </div>
    <v-row>
      <v-col cols="12" md="6">
        <div >
          <img class="avatar" alt="Lourdes Logo" src="@/assets/lourdesImagen.png"/>  
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div>
          <img class="lourdesLogo" alt="Lourdes Logo" src="@/assets/logo-lourdes.png"/>
        </div>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  methods: {

  }
}
</script>

<style scoped>
  .home-container{
    background-color: #D9AFD9;
    background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%); 
  }
  .avatar{
    animation: backInLeft;
    animation-duration: 2s;
    height: 400px;
    box-shadow: 11px 12px 20px rgb(0 0 0 / 50%);
    border-radius: 20px;
  }
  .lourdesLogo{
    animation: bounce;
    animation-duration: 2s;
  }
  h1{
    font-size: 40px;
    color:#4f28a3;
  }
  .imagen{
    box-shadow: 8px 10px 17px 1px #888899;
    animation: backInDown;
    animation-duration: 2s;
  }
  @keyframes fade-in {
    0%   {visibility: hidden;}
    100% {visibility: visible;}
  }
  .mkt{
    text-align: center;
    animation-name: fade-in;
    animation-duration: 2s;
  }
  .mkt img{
    max-width: -webkit-fill-available;
  }
  .btn-market{
        background: #17cb55;
        padding: 20px;
        font-size: xx-large;
        color: #000;
        border-radius: 5%;
        position: relative;
        top: -105px;
        box-shadow: 7px 7px 13px 2px;
  }

  @media all and (max-width: 3000px) {
    .avatar{
      margin-left: 31vw;
    }
    .lourdesLogo{
      margin-top: 3vw;
    }
  }
  @media all and (max-width: 1440px) {
    .avatar{
      margin-left: 18vw;
    }
    .lourdesLogo{
      margin-top: 5vw;
    }
  }
  @media all and (max-width: 1120px) {
    .avatar{
      margin-left: 12vw;
    }
    .lourdesLogo{
      width: 100%;
      padding: 12px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  @media all and (max-width: 959px) {
    .avatar{
        width: 37%;
        height: auto;
        margin-left: 29vw;
        margin-bottom: 6vw;
    }
    .lourdesLogo{
      width: 77%;
      padding: 0 37px 0 52px;
      margin-right: auto;
      margin-left: 13vw;
      margin-top: -5vw;
    }
    .imagen{
     width: 350px !important;
     height: 196px !important;
   }
   .btn-market{
      padding: 5px;
      font-size: small;
      top: -40px;
      box-shadow: 3px 3px 25px 1px;
    }
  }
</style>
