<template>
  <!-- <v-container class="market-container" :style="{'background-image': `url(${require('@/assets/market-background.jpg')})`}" fluid> -->
  <v-container class="home-container" fluid>
      <div class="mkt">
        <h1 class="titulo">Slater, Tienda Hispana</h1>
        <h3>Slater, Hispanic Market</h3>
          <div class="market-image">
            <img loading="lazy" src="@/assets/hispanic-market.png" alt="">
          </div>
        <div class="sub-titulo">
          <h2>Vamos a comprar nuestros víveres!</h2>
          <h3>Let's buy our groceries!</h3>
        </div>
        <div class="total-parent">
            <div class="total" @click="bill()">
              <h2>Total: $ {{this.total.toFixed(2)}}</h2>
            </div>
        </div>
        <div class="catalog">
            <div class="food">
                <h2>Comida</h2>
                <h3>Food</h3>
                <div class="category">
                  <div class="product"><h3 class="nombre-producto">{{this.products[0].nombre}}</h3><h4 class="product-name">{{this.products[0].name}}</h4><h4 class="precio">Precio: $ {{this.products[0].price}}</h4><h5>Price: $ {{this.products[0].price}}</h5><img loading="lazy" src="@/assets/pan.png" alt="product"><h2>{{this.products[0].quantity}}</h2><div class="cantidad"><button class="minus" @click="restar(0)">-</button><div><h3>Cantidad</h3><h4>Quantity</h4></div><button class="plus" @click="sumar(0)">+</button></div></div>
                  <div class="product"><h3 class="nombre-producto">{{this.products[1].nombre}}</h3><h4 class="product-name">{{this.products[1].name}}</h4><h4 class="precio">Precio: $ {{this.products[1].price}}</h4><h5>Price: $ {{this.products[1].price}}</h5><img loading="lazy" src="@/assets/tortillas.png" alt="product"><h2>{{this.products[1].quantity}}</h2><div class="cantidad"><button class="minus" @click="restar(1)">-</button><div><h3>Cantidad</h3><h4>Quantity</h4></div><button class="plus" @click="sumar(1)">+</button></div></div>
                  <div class="product"><h3 class="nombre-producto">{{this.products[2].nombre}}</h3><h4 class="product-name">{{this.products[2].name}}</h4><h4 class="precio">Precio: $ {{this.products[2].price}}</h4><h5>Price: $ {{this.products[2].price}}</h5><img loading="lazy" src="@/assets/carne.png" alt="product"><h2>{{this.products[2].quantity}}</h2><div class="cantidad"><button class="minus" @click="restar(2)">-</button><div><h3>Cantidad</h3><h4>Quantity</h4></div><button class="plus" @click="sumar(2)">+</button></div></div>
                  <div class="product"><h3 class="nombre-producto">{{this.products[3].nombre}}</h3><h4 class="product-name">{{this.products[3].name}}</h4><h4 class="precio">Precio: $ {{this.products[3].price}}</h4><h5>Price: $ {{this.products[3].price}}</h5><img loading="lazy" src="@/assets/pollo.png" alt="product"><h2>{{this.products[3].quantity}}</h2><div class="cantidad"><button class="minus" @click="restar(3)">-</button><div><h3>Cantidad</h3><h4>Quantity</h4></div><button class="plus" @click="sumar(3)">+</button></div></div>
                </div>
                
            </div>
            <div class="beverages">
                <h2>Bebidas</h2>
                <h3>Beverages</h3>
                <div class="category">
                  <div class="product"><h3 class="nombre-producto">{{this.products[4].nombre}}</h3><h4 class="product-name">{{this.products[4].name}}</h4><h4 class="precio">Precio: $ {{this.products[4].price}}</h4><h5>Price: $ {{this.products[4].price}}</h5><img loading="lazy" src="@/assets/agua.png" alt="product"><h2>{{this.products[4].quantity}}</h2><div class="cantidad"><button class="minus" @click="restar(4)">-</button><div><h3>Cantidad</h3><h4>Quantity</h4></div><button class="plus" @click="sumar(4)">+</button></div></div>
                  <div class="product"><h3 class="nombre-producto">{{this.products[5].nombre}}</h3><h4 class="product-name">{{this.products[5].name}}</h4><h4 class="precio">Precio: $ {{this.products[5].price}}</h4><h5>Price: $ {{this.products[5].price}}</h5><img loading="lazy" src="@/assets/jugo.png" alt="product"><h2>{{this.products[5].quantity}}</h2><div class="cantidad"><button class="minus" @click="restar(5)">-</button><div><h3>Cantidad</h3><h4>Quantity</h4></div><button class="plus" @click="sumar(5)">+</button></div></div>
                  <div class="product"><h3 class="nombre-producto">{{this.products[6].nombre}}</h3><h4 class="product-name">{{this.products[6].name}}</h4><h4 class="precio">Precio: $ {{this.products[6].price}}</h4><h5>Price: $ {{this.products[6].price}}</h5><img loading="lazy" src="@/assets/leche.png" alt="product"><h2>{{this.products[6].quantity}}</h2><div class="cantidad"><button class="minus" @click="restar(6)">-</button><div><h3>Cantidad</h3><h4>Quantity</h4></div><button class="plus" @click="sumar(6)">+</button></div></div>
                  <div class="product"><h3 class="nombre-producto">{{this.products[7].nombre}}</h3><h4 class="product-name">{{this.products[7].name}}</h4><h4 class="precio">Precio: $ {{this.products[7].price}}</h4><h5>Price: $ {{this.products[7].price}}</h5><img loading="lazy" src="@/assets/gaseosa.png" alt="product"><h2>{{this.products[7].quantity}}</h2><div class="cantidad"><button class="minus" @click="restar(7)">-</button><div><h3>Cantidad</h3><h4>Quantity</h4></div><button class="plus" @click="sumar(7)">+</button></div></div>
                </div>
                
            </div>
            <div class="vegetables">
                <h2>Vegetales</h2>
                <h3>Vegetables</h3>
                <div class="category">
                  <div class="product"><h3 class="nombre-producto">{{this.products[8].nombre}}</h3><h4 class="product-name">{{this.products[8].name}}</h4><h4 class="precio">Precio: $ {{this.products[8].price}}</h4><h5>Price: $ {{this.products[8].price}}</h5><img loading="lazy" src="@/assets/maiz.png" alt="product"><h2>{{this.products[8].quantity}}</h2><div class="cantidad"><button class="minus" @click="restar(8)">-</button><div><h3>Cantidad</h3><h4>Quantity</h4></div><button class="plus" @click="sumar(8)">+</button></div></div>
                  <div class="product"><h3 class="nombre-producto">{{this.products[9].nombre}}</h3><h4 class="product-name">{{this.products[9].name}}</h4><h4 class="precio">Precio: $ {{this.products[9].price}}</h4><h5>Price: $ {{this.products[9].price}}</h5><img loading="lazy" src="@/assets/frijoles.png" alt="product"><h2>{{this.products[9].quantity}}</h2><div class="cantidad"><button class="minus" @click="restar(9)">-</button><div><h3>Cantidad</h3><h4>Quantity</h4></div><button class="plus" @click="sumar(9)">+</button></div></div>
                  <div class="product"><h3 class="nombre-producto">{{this.products[10].nombre}}</h3><h4 class="product-name">{{this.products[10].name}}</h4><h4 class="precio">Precio: $ {{this.products[10].price}}</h4><h5>Price: $ {{this.products[10].price}}</h5><img loading="lazy" src="@/assets/tomates.png" alt="product"><h2>{{this.products[10].quantity}}</h2><div class="cantidad"><button class="minus" @click="restar(10)">-</button><div><h3>Cantidad</h3><h4>Quantity</h4></div><button class="plus" @click="sumar(10)">+</button></div></div>
                  <div class="product"><h3 class="nombre-producto">{{this.products[11].nombre}}</h3><h4 class="product-name">{{this.products[11].name}}</h4><h4 class="precio">Precio: $ {{this.products[11].price}}</h4><h5>Price: $ {{this.products[11].price}}</h5><img loading="lazy" src="@/assets/cebollas.png" alt="product"><h2>{{this.products[11].quantity}}</h2><div class="cantidad"><button class="minus" @click="restar(11)">-</button><div><h3>Cantidad</h3><h4>Quantity</h4></div><button class="plus" @click="sumar(11)">+</button></div></div>
                </div>
                
            </div>
        </div>
      </div>
    
  </v-container>
</template>

<script>

export default {
  name: 'Market',
  data() {
    return {
      total: 0,
      check:'',
      image: '@/assets/market-background.jpg',
      products: [{
          id: 0,
          name: 'Bread',
          nombre: 'Pan',
          price: 2.00,
          quantity: 0,
        },
        {
          id: 1,
          name: 'Tortilla',
          nombre: 'Tortilla',
          price: 1.50,
          quantity: 0,
        },
        {
          id: 2,
          name: 'Meat',
          nombre: 'Carne',
          price: 4.80,
          quantity: 0,
        },
        {
          id: 3,
          name: 'Chicken',
          nombre: 'Pollo',
          price: 3.50,
          quantity: 0,
        },
        {
          id: 4,
          name: 'Water',
          nombre: 'Agua',
          price: 0.95,
          quantity: 0,
        },
        {
          id: 5,
          name: 'Juice',
          nombre: 'Jugo',
          price: 1.30,
          quantity: 0,
        },
        {
          id: 6,
          name: 'Milk',
          nombre: 'Leche',
          price: 1.25,
          quantity: 0,
        },
        {
          id: 7,
          name: 'Soda',
          nombre: 'Gaseosa',
          price: 2.50,
          quantity: 0,
        },
        {
          id: 8,
          name: 'Corn',
          nombre: 'Maiz',
          price: 2.00,
          quantity: 0,
        },
        {
          id: 9,
          name: 'Beans',
          nombre: 'Frijoles',
          price: 1.80,
          quantity: 0,
        },
        {
          id: 10,
          name: 'Tomatoes',
          nombre: 'Tomates',
          price: 0.6,
          quantity: 0,
        },
        {
          id: 11,
          name: 'Onions',
          nombre: 'Cebollas',
          price: 0.75,
          quantity: 0,
        }]
      }
  },
  components: {
  },
  methods: {
    sumar(i){
      this.products[i].quantity++;
      this.cuenta();
    },
    restar(i){
      if(this.products[i].quantity >= 1){
        this.products[i].quantity--;
        this.cuenta();
      }
    },
    cuenta(){
      this.total = 0;
      for(var i=0; i<this.products.length; i++){
        this.total = this.total + (this.products[i].price)*(this.products[i].quantity);
      }
    },
    bill(){
      var subTotal = 0;
      var Total = 0;
      this.check = 'TU FACTURA' + '\n ' + 'Producto   Cantidad    subtotal' + '\n' + '---------------------------------------' + '\n';
      for (var i=0;i<this.products.length;i++){
        
        if(this.products[i].quantity != 0){
          subTotal = (this.products[i].quantity*this.products[i].price);
          this.check += this.products[i].nombre + '               ' + this.products[i].quantity + '               $' + subTotal.toFixed(2) + '\n';
          Total += subTotal;
        }
      }
      this.check += 'Total:    $' + Total.toFixed(2);
      alert(this.check); 
    },
    }
  }
</script>

<style scoped>

  .titulo{
        font-size: 60px;
        color: #e077d9;
        -webkit-text-fill-color:  #e077d9; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #4f28a3;
    }

  .catalog{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    padding: 1rem;
    /* border-style: solid;
    border-radius: 20px; */
  }

  .catalog h2{
    font-family: 'lobster';
    font-size: 35px;
  }

  .food, 
  .beverages, 
  .vegetables{
    border-style: inset;
    border-radius: 20px;
    margin: 0 10px;
  }

  .category{
    display:grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(2,1fr);
  }

  .product{
    padding: 2rem;
    border-style: hidden;
    border-radius: 20px;
    margin: 5px;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .product img{
    transition: transform .2s; /* Animation */
  }

  .product img:hover{
    transform: scale(1.5);
  }

  .nombre-producto{
    font-family: 'lobster';
    font-size:25px;
  }

  .precio{
    border-style: solid;
    border-radius: 20px;
    background-color: salmon;
  }

  .product img{
    padding: 1rem;
  }

  .cantidad{
    display: grid;
    grid-template-columns: 20px auto 20px;
    align-items:baseline;
  }

  .cantidad button{
    border-radius: 50%;
  }

  .minus{
    background-color: red;
    color: white;
  }

  .plus{
    background-color: green;
    color:white;
  }

  .home-container{
    background-color: #D9AFD9;
    background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%); 
  }
  .avatar{
    animation: backInLeft;
    animation-duration: 2s;
  }
  .lourdesLogo{
    animation: bounce;
    animation-duration: 2s;
  }
  h1{
    font-size: 40px;
    color:#4f28a3;
  }
  .imagen{
    box-shadow: 8px 10px 17px 1px #888899;
    animation: backInDown;
    animation-duration: 2s;
  }
  @keyframes fade-in {
    0%   {visibility: hidden;}
    100% {visibility: visible;}
  }
  .mkt{
    text-align: center;
    animation-name: fade-in;
    animation-duration: 2s;
  }
  
  .mkt img{
    max-width: -webkit-fill-available;
  }

  .sub-titulo{
    justify-content: center;
    padding-bottom: 3rem;
    font-family: 'lobster';
    font-size: 20px;
    color:#4f28a3;
  }

  .total{
    background-color: red;
    width: 223px;
    color: white;
    font-size: medium;
    margin-bottom: 4rem;
    border-radius: 30px;
    box-shadow: 6px 7px 20px rgb(0 0 0 / 50%);
    cursor: pointer;
    position: fixed;
    top: 90vh;
    right: 1vw;
  }
  .total-parent{
    display: flex;
    justify-content: center;
  }
  @media all and (max-width: 1250px) {
    .catalog{
      grid-template-columns: repeat(1,1fr);
    }
  }

</style>
