<template>
  <v-container class="home-container" fluid>
      <div class="p-5 text-center">
          <h1 class="titulo">EL CUERPO HUMANO</h1>
          <h2 class="font-italic">The human body</h2>
          <div class="pt-10 text-center">
              <img class = "cuerpo" src="@/assets/cuerpo.png" alt="Partes del cuerpo">
          </div>
          <div>
              <img class = "hair" src="@/assets/hair.png" alt="Hair" @click="reproduccion(0)">
          </div>
          <div>
              <img class = "head" src="@/assets/head.png" alt="Head" @click="reproduccion(1)">
          </div>
          <div>
              <img class = "ear" src="@/assets/ear.png" alt="Ear" @click="reproduccion(2)">
          </div>
          <div>
              <img class = "mouth" src="@/assets/mouth.png" alt="Mouth" @click="reproduccion(3)">
          </div>
          <div>
              <img class = "arm" src="@/assets/arm.png" alt="Arm" @click="reproduccion(4)">
          </div>
          <div>
              <img class = "leg" src="@/assets/leg.png" alt="Leg" @click="reproduccion(5)">
          </div>
          <div>
              <img class = "feet" src="@/assets/feet.png" alt="Feet" @click="reproduccion(6)">
          </div>
          <div>
              <img class = "eyes" src="@/assets/eyes.png" alt="Eyes" @click="reproduccion(7)">
          </div>
          <div>
              <img class = "nose" src="@/assets/nose.png" alt="Nose" @click="reproduccion(8)">
          </div>
          <div>
              <img class = "shoulder" src="@/assets/shoulder.png" alt="Shoulder" @click="reproduccion(9)">
          </div>
          <div>
              <img class = "hand" src="@/assets/hand.png" alt="Hand" @click="reproduccion(10)">
          </div>
          <div>
              <img class = "knee" src="@/assets/knee.png" alt="Knee" @click="reproduccion(11)">
          </div>
          <div>
              <img class = "foot" src="@/assets/foot.png" alt="Foot" @click="reproduccion(12)">
          </div>
          
      </div>
      
  </v-container>
  
</template>

<script>
export default {
  name: 'Cuerpo',
  data(){
    return{
      index: 0,
      source: '',
      audios: [{
        id: 0,
        src: '@/assets/pelo.mp3'
      },
      {
        id: 1,
        src: '@/assets/yogur.mp3'
      }]
    }
  },
  methods:{
    reproduccion(pos){
      if (pos == 0){
        var audio = new Audio(require('@/assets/pelo.mp3'));
        audio.play();
      }
      else if(pos == 1){
        var audio = new Audio(require('@/assets/cabeza.mp3'));
        audio.play();
      }
      if (pos == 2){
        var audio = new Audio(require('@/assets/oreja.mp3'));
        audio.play();
      }
      else if(pos == 3){
        var audio = new Audio(require('@/assets/boca.mp3'));
        audio.play();
      }
      if (pos == 4){
        var audio = new Audio(require('@/assets/brazo.mp3'));
        audio.play();
      }
      else if(pos == 5){
        var audio = new Audio(require('@/assets/pierna.mp3'));
        audio.play();
      }
      if (pos == 6){
        var audio = new Audio(require('@/assets/pies.mp3'));
        audio.play();
      }
      else if(pos == 7){
        var audio = new Audio(require('@/assets/ojos.mp3'));
        audio.play();
      }
      if (pos == 8){
        var audio = new Audio(require('@/assets/nariz.mp3'));
        audio.play();
      }
      else if(pos == 9){
        var audio = new Audio(require('@/assets/hombro.mp3'));
        audio.play();
      }
      else if(pos == 10){
        var audio = new Audio(require('@/assets/mano.mp3'));
        audio.play();
      }
      else if(pos == 11){
        var audio = new Audio(require('@/assets/rodilla.mp3'));
        audio.play();
      }
      else if(pos == 12){
        var audio = new Audio(require('@/assets/pie.mp3'));
        audio.play();
      }

    }
  }
  
}
</script>

<style scoped>
.home-container{
    background-color: #D9AFD9;
    background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%); 
  }
.titulo{
      font-size: 60px;
      color: #e077d9;
      -webkit-text-fill-color:  #e077d9; /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #4f28a3;
  }

  .hair{
      position: absolute;
      top:180px;
      margin-left: -350px;
      height: 60px;
  }
  .head{
      position: absolute;
      top: 220px;
      margin-left: 53px;
      height: 60px;
  }
  .ear{
      position: absolute;
      top: 284px;
      margin-left: 86px;
      height: 60px;
  }
  .mouth{
      position: absolute;
      top: 324px;
      margin-left: 6px;
      height: 45px;
  }
  .arm{
      position: absolute;
      top: 390px;
      margin-left: 73px;
      height: 58px;
  }
  .leg{
      position: absolute;
      top: 496px;
      margin-left: 48px;
      height: 58px;
  }
  .feet{
      position: absolute;
      top: 601px;
      margin-left: 57px;
      height: 58px;
  }
  .eyes{
      position: absolute;
      top: 255px;
      margin-left: -306px;
      height: 60px;
  }
  .nose{
      position: absolute;
      top: 288px;
      margin-left: -191px;
      height: 49px;
  }
  .shoulder{
      position: absolute;
      top: 347px;
      margin-left: -346px;
      height: 60px;
  }
  .hand{
        position: absolute;
        top: 458px;
        margin-left: -229px;
        height: 60px;
  }
  .knee{
        position: absolute;
        top: 526px;
        margin-left: -290px;
        height: 60px;
  }
  .foot{
        position: absolute;
        top: 600px;
        margin-left: -290px;
        height: 60px;
  }
  
  @media all and (max-width: 959px){
  .titulo{
           font-size: 40px; 
  }
  .cuerpo{
    width: 350px;
  }
  .hair{
    top: 159px;
    margin-left: -242px;
    height: 40px;
  }
  .head{
    top: 184px;
    margin-left: 41px;
    height: 40px;
  }
  .ear{
    top: 220px;
    margin-left: 62px;
    height: 40px;
  }
  .mouth{
    top: 250px;
    margin-left: 6px;
    height: 30px;
  }
  .arm{
    top: 295px;
    margin-left: 47px;
    height: 38px;
  }
  .leg{
    top: 378px;
    margin-left: 37px;
    height: 38px;
  }
  .feet{
    top: 446px;
    margin-left: 37px;
    height: 38px;
  }
  .eyes{
    top: 206px;
    margin-left: -205px;
    height: 40px;
  }
  .nose{
    top: 228px;
    margin-left: -130px;
    height: 34px;
  }
  .shoulder{
    top: 266px;
    margin-left: -232px;
    height: 40px;
  }
  .hand{
    top: 344px;
    margin-left: -149px;
    height: 40px;
  }
  .knee{
    top: 393px;
    margin-left: -198px;
    height: 40px;
  }
  .foot{
    top: 440px;
    margin-left: -198px;
    height: 40px;
  }
}
  @media all and (max-width: 500px){
      .hair{
        top: 159px;
        margin-left: -205px;
        height: 33px;
      }
      .head{
        top: 184px;
        margin-left: 41px;
        height: 33px;
      }
      .ear{
        top: 226px;
        margin-left: 61px;
        height: 33px;
      }
      .mouth{
        top: 250px;
        margin-left: 6px;
        height: 25px;
      }
      .arm{
        top: 300px;
        margin-left: 50px;
        height: 30px;
      }
      .leg{
        top: 381px;
        margin-left: 35px;
        height: 30px;
      }
      .feet{
        top: 448px;
        margin-left: 35px;
        height: 30px;
      }
      .eyes{
        top: 210px;
        margin-left: -178px;
        height: 33px;
      }
      .nose{
        top: 228px;
        margin-left: -115px;
        height: 29px;
      }
      .shoulder{
        top: 266px;
        margin-left: -192px;
        height: 33px;
      }
      .hand{
        top: 348px;
        margin-left: -114px;
        height: 33px;
      }
      .knee{
        top: 397px;
        margin-left: -167px;
        height: 33px;
      }
      .foot{
        top: 440px;
        margin-left: -167px;
        height: 33px;
      }

  }
  @media all and (max-width: 415px){
      .hair{
        top: 220px;
        margin-left: -178px;
        height: 28px;
      }
      .head{
        top: 246px;
        margin-left: 41px;
        height: 28px;
      }
      .ear{
        top: 288px;
        margin-left: 65px;
        height: 28px;
      }
      .mouth{
        top: 313px;
        margin-left: 6px;
        height: 24px;
      }
      .arm{
        top: 358px;
        margin-left: 47px;
        height: 26px;
      }
      .leg{
        top: 439px;
        margin-left: 35px;
        height: 26px;
      }
      .feet{
        top: 512px;
        margin-left: 35px;
        height: 26px;
      }
      .eyes{
        top: 270px;
        margin-left: -159px;
        height: 28px;
      }
      .nose{
        top: 290px;
        margin-left: -110px;
        height: 28px;
      }
      .shoulder{
        top: 330px;
        margin-left: -172px;
        height: 28px;
      }
      .hand{
        top: 409px;
        margin-left: -89px;
        height: 28px;
      }
      .knee{
        top: 461px;
        margin-left: -145px;
        height: 28px;
      }
      .foot{
        top: 510px;
        margin-left: -145px;
        height: 28px;
      }
  }
</style>
