<template>
  <v-container class="home-container" fluid>
      <div class="content">
        <h1 class="titulo text-center" data-hover="MES DE LA HISTORIA NEGRA">BLACK HISTORY MONTH</h1>
        <div class="images text-center"><img src="@/assets/celiacruz.jpg" alt="Celia Cruz"></div>
        <div class="col-xs-12 col-md-9 offset-md-2">
            <div class="title-1 pb-10" data-hover="Una identidad oculta: 10 afrolatinos famosos para celebrar durante el Mes de la Historia Negra"><h1>A Hidden Identity: 10 famous Afro-Latinos to celebrate during Black History Month</h1></div>
            <div class="paragraph-1" data-hover="La identidad afrolatina (o negra latina) es una que todavía viene con un estigma."><p>The Afro-Latino (or Black Latino) identity is one that still comes with a stigma.</p></div>
            <div class="paragraph-1" data-hover="Según el censo de 2010, solo alrededor del 3 por ciento de los hispanos se identificaron como “negros solos”. Este bajo porcentaje habla de una larga historia de vergüenza y desgana en la comunidad latina para identificarse con sus raíces indígenas africanas; principalmente debido a las asociaciones negativas que rodean al ser negro en los EE. UU."><p>According to the 2010 Census, only around 3 percent of Hispanics identified as “Black alone”. This low percentage speaks to a long history of shame and reluctance in the Latino community to identify with their indigenous African roots; mainly due to the negative associations that surround being black in the U.S.</p></div>
            <div class="paragraph-1" data-hover="A pesar de esto, hay muchos afrolatinos notables que son conocidos por sus contribuciones a los deportes, la música, las películas y las artes estadounidenses. Elegimos 10 de ellos que vale la pena recordar este mes:"><p>Despite this, there are many notable Afro-Latinos who are known for their contributions to U.S. sports, music, films, and arts. We picked out 10 of them worth remembering this month:</p></div>
            <h2 class="pb-5">Celia Cruz</h2>
            <div class="paragraph-1" data-hover="Popularmente conocida como la 'Reina de la Salsa' o la 'Reina de la Música Latina', esta afrocubana abrazó con orgullo su herencia a través de su música. Su carrera como músico comenzó en Cuba en la década de 1950, donde recibió por primera vez el nombre de “La Guarachera de Cuba” debido a su popularidad en el canto de guaracheras (un tipo de música originaria de Cuba)."><p>Popularly known as the 'Queen of Salsa' or the 'Queen of Latin Music,' this Afro-Cuban proudly embraced her heritage through her music. Her career as a musician started in Cuba in the 1950s, where she first received the name 'La Guarachera de Cuba' because of her popularity in singing guaracheras (a type of music originated from Cuba).</p></div>
            <div class="pt-3 text-center">
              <iframe class="vid" width="560" height="315" src="https://www.youtube.com/embed/imeXSRNRMeg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div> 
            <div class="paragraph-1" data-hover="Cuando Cuba comenzó a nacionalizar la industria de la música en la década de 1960, se mudó a México y luego a Estados Unidos para continuar su carrera musical. Se hizo cada vez más popular durante este tiempo, lanzando innumerables canciones exitosas. Estas canciones fueron únicas en la forma en que trajeron muchos estilos musicales afrocubanos diferentes a la corriente principal. Además de esto, lanzó canciones como “La negra tiene tumbao”, que permitió asociar la identidad de Black Latina con el orgullo y la celebración, en lugar de la vergüenza y el ridículo. A lo largo de su vida, Cruz obtuvo éxito internacional con sus 37 álbumes de estudio y sigue siendo un nombre familiar incluso después de su muerte en 2003."><p>When Cuba began to nationalize the music industry in the 1960s, she moved on to Mexico, and later the United States to continue her music career. She became increasingly popular during this time, releasing countless successful songs. These songs were unique in how they brought many different Afro-Cuban music styles into the mainstream. On top of this, she released songs like “La negra tiene tumbao”, which allowed the Black Latina identity to be associated with pride and celebration, rather than shame and ridicule. Throughout her life, Cruz gained international success with her 37 studio albums and still remains a household name even after her death in 2003.</p></div>
            <h2 class="pt-5 mb-5">Rosie Perez</h2>
            <div class="paragraph-1" data-hover="Nacida en Brooklyn, Nueva York, esta afrolatina abraza con orgullo su identidad como puertorriqueña negra. Consiguió su primer papel importante actoral en la exitosa película de Spike Lee 'Do the Right Thing'. A partir de ahí, continuó su carrera como actriz en películas como 'Los hombres blancos no pueden saltar' y 'Sin miedo'. Su carrera también incluyó ser coreógrafa, autora, bailarina y presentadora de programas de entrevistas."><p>Born in Brooklyn, New York, this Afro-Latina proudly embraces her identity as a Black Puerto Rican. She got her first major acting role in Spike Lee’s hit movie “Do the Right Thing.” From there, she went on to continue her acting career in movies like “White Men Can’t Jump” and “Fearless.” Her career also included being a choreographer, author, dancer, and talk show host.</p></div>
            <div class="pt-10 text-center">
              <iframe class="vid" width="560" height="315" src="https://www.youtube.com/embed/739XYgoA-x8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div class="paragraph-1" data-hover="Pérez también ha estado involucrado en la comunidad puertorriqueña de muchas maneras. Codirigió el documental “¡Yo soy Boricua, pa’que tú lo sepas!”, Película que describe la historia de los puertorriqueños. Pérez también participó en una campaña española de anuncios de servicio público sobre el SIDA, que luego la llevó a ser nombrada miembro del Consejo Asesor Presidencial sobre VIH / SIDA (PACHA) por Barack Obama en 2010."><p>Perez has also been involved in the Puerto Rican community in a multitude of ways. She co-directed the documentary “¡Yo soy Boricua, pa’que tú lo sepas!”, a film that describes the history of Puerto Ricans. Perez also participated in a Spanish AIDS public service annoucement campaign, which later led to her being appointed to the Presidential Advisory Council on HIV/AIDs (PACHA) by Barack Obama in 2010.</p></div>
            <h2 class="pb-5">Zoe Saldana</h2>
            <div class="images text-center pb-5"><img src="@/assets/zoeSaldana.jpg" alt="Zoe Saldana"></div>
            <div class="paragraph-1" data-hover="Saldana es más reconocida por sus papeles en dos de las películas más taquilleras de todos los tiempos, 'Avatar' y 'Avengers: Endgame'. Nacida de padre dominicano y madre puertorriqueña, Saldana no se disculpa por su identidad como afrolatina. Ella ha sido muy franca sobre su experiencia como latina negra. En múltiples entrevistas, rechaza la noción de que las afrolatinas deberían avergonzarse de su herencia y, en cambio, deberían estar orgullosas de sus raíces indígenas africanas."><p>Saldana is most recognized for her roles in two of the largest grossing movies of all-time, “Avatar” and “Avengers: Endgame.” Born to a Dominican father and Puerto Rican mother, Saldana makes no apologies for her identity as an Afro-Latina. She has been very outspoken about her experience as a Black Latina. In multiple interviews, she rejects the notion that Afro-Latinas should be ashamed of their heritage, and instead should be proud of their indigenous African roots.</p></div>
            <h2 class="pb-5">Miriam Jiménez Román</h2>
            <div class="images text-center pb-5"><img src="https://remezcla.com/wp-content/uploads/2020/08/Screen-Shot-2020-08-10-at-9.52.39-AM.png" alt="Maria Jimenez Roman"></div>
            <div class="paragraph-1" data-hover="Román usó su experiencia como puertorriqueña negra para allanar el camino para los afrolatinos en todas partes. Su libro, “The Afro-Latin @ Reader: Historia y cultura en los Estados Unidos”, le dio credibilidad y reconocimiento a la identidad afrolatina y dio grandes pasos para promover la comprensión de lo que es un negro latino. Durante ese tiempo, no hubo muchos intentos de confrontar las complejidades que rodean a la afrolatinidad, por lo que este libro fue único."><p>Román used her experience as a Black Puerto Rican to pave the way for Afro-Latinos everywhere. Her book, “The Afro-Latin@ Reader: History and Culture in the United States,” gave credibility and recognition to the Afro-Latino identity and it made great strides to promote understanding about what a Black Latino is. During that time, there weren’t many attempts to confront the complexities surrounding Afro-Latinidad, so this book was one-of-a-kind.</p></div>
            <div class="paragraph-1" data-hover="También encabeza el “Afro-Latin @ Forum”, que es otra forma en que Jiménez Román se esfuerza por generar conciencia y orgullo por la afrolatinidad."><p>She also heads the “Afro-Latin@ Forum”, which is another way Jiménez Román strives to bring awareness and pride to Afro-Latinidad.</p></div>
            <h2 class="pb-5 mt-5">Elizabeth Acevedo</h2>
            <div class="images text-center pb-5"><img src="@/assets/ElizabethAcevedo.jpg" alt="Maria Jimenez Roman"></div>
            <div class="paragraph-1" data-hover="Al identificarse con orgullo como afrolatina, Acevedo no se avergüenza de su origen dominicano y puertorriqueño."><p>Proudly identifying as a Afro-Latina, Acevedo is not ashamed of her Dominican and Puerto Rican background.</p></div>
            <div class="paragraph-1" data-hover="Ha tenido un gran éxito como poeta y autora. Esto se debe en gran parte a cómo ella involucra sus propias experiencias como latina negra en su trabajo. Acevedo observó la falta de diversidad en los libros para niños (especialmente para las personas que se parecían a ella) y lo vio como una oportunidad para compartir su voz. Hasta ahora, Acevedo ha escrito tres libros: 'El Poeta X', 'Con el fuego en lo alto' y 'Aplaude cuando aterrices'."><p>She has found major success as a poet and author. This is largely due to how she involves her own experiences as a Black Latina in her work. Acevedo observed the lack of diversity in children’s books (especially for people who looked like her) and saw it as an opportunity to share her voice. So far, Acevedo has written three books: “The Poet X, “With the Fire on High” and “Clap When You Land.”</p></div>
            <div class="paragraph-1" data-hover="“El Poeta X” fue especialmente exitoso. Es un bestseller del New York Times, ganadora de la Medalla Carnegie y del Premio Nacional del Libro. La propia Acevedo ha ganado múltiples premios, incluido el premio Boston-Globe Hornbook Award a la mejor ficción infantil de 2018."><p>“The Poet X” was especially successful. It is a New York Times Bestseller, Carnegie Medal and National Book Award Winner. Acevedo herself has won multiple awards, including the Boston-Globe Hornbook Award Prize for Best Children’s Fiction of 2018.</p></div>
            <h2 class="pb-5 mt-5">Maritza Correia McClendon</h2>
            <div class="images text-center pb-5"><img src="@/assets/Maritza-McClendon.jpg" alt="Maria Jimenez Roman"></div>
            <div class="paragraph-1" data-hover="Correia McClendon tiene el honor de ser la primera puertorriqueña negra en unirse al equipo olímpico de EE. UU."><p>Correia McClendon has the honor of being the first Black Puerto Rican to join the U.S. Olympic team.</p></div>
            <div class="paragraph-1" data-hover="En su juventud, su médico descubrió que tenía una escoliosis grave y le sugirió que comenzara a nadar como tratamiento. A pesar de su condición, Correia McClendon pasó a hacer historia al establecer récords de natación estadounidenses y mundiales. A lo largo de su carrera, ha ganado innumerables campeonatos de la NCAA e incluso se ha llevado a casa una medalla de plata por su desempeño en los 400 metros estilo libre en los Juegos de Verano en Atenas, Grecia."><p>In her youth, her doctor discovered that she had severe scoliosis, and suggested that she take up swimming as treatment. Despite her condition, Correia McClendon went on to make history by setting American and world swimming records. Throughout her career, she has won countless NCAA championships and even brought home a silver medal for her performance in the 400-meter freestyle at the the Summer Games in Athens, Greece.</p></div>
            <div class="paragraph-1" data-hover="Correia McClendon, ejecutiva de marketing, ha hecho de su vida ayudar a los niños afroamericanos de todo el país a aprender a nadar."><p>A marketing executive, Correia McClendon has made it a life’s journey to help African-American children around the country learn to swim.</p></div>
            <h2 class="pb-5 mt-5">Esteban Hotesse</h2>
            <div class="images text-center pb-5"><img src="@/assets/esteban_hotesse.jpg" alt="Esteban"></div>
            <div class="paragraph-1" data-hover="Un hecho descubierto recientemente en 2015 por el académico Edward de Jesus, Esteban Hotesse fue el único dominicano conocido que sirvió como aviador de Tuskegee durante la Segunda Guerra Mundial. Aunque se crió en la ciudad de Nueva York, emigró de la República Dominicana cuando era niño a la edad de cuatro años."><p>A recently discovered fact in 2015 by academic Edward de Jesus, Esteban Hotesse was the only known Dominican to serve as a Tuskegee airman during World War II. Though raised in New York City, he immigrated from the Dominican Republic as a child at the age of four.</p></div>
            <div class="paragraph-1" data-hover="Según Tuskegee Airmen Inc., los aviadores de Tuskegee fueron los que participaron en un programa destinado a enseñar a los afroamericanos cómo operar correctamente los aviones. De Jesus se enteró de que Hotesse se puso de pie, junto con otros 101 aviadores, contra las órdenes segregacionistas de los oficiales del ejército de los EE. UU. Hotesse fue ascendido a segundo teniente y estaba en su misión número 68 con el escuadrón All Black cuando murió en julio de 1945. Tenía 26 años."><p>According to Tuskegee Airmen Inc., the Tuskegee airmen were those who participated in a program aimed at teaching African Americans how to properly operate aircraft. De Jesus learned that Hotesse stood up, alongside 101 other airmen, against the segregationist orders of U.S. Army officials. Hotesse was promoted to second lieutenant and was on his 68th mission with the all Black squadron when he died in July 1945. He was 26 years old.</p></div>
            <div class="paragraph-1" data-hover="En 2018, Hotesse recibió póstumamente la Medalla de Oro del Congreso por su valentía por parte del primer congresista dominicano-estadounidense de Estados Unidos, Adriano Espaillat."><p>In 2018, Hotesse was posthumously awarded the Congressional Gold Medal for his bravery by the first Dominican-American U.S. Congressman, Adriano Espaillat.</p></div>
            <h2 class="pb-5 mt-5">Esperanza Spalding</h2>
            <div class="images text-center pb-5"><img src="@/assets/esperanza-spalding.jpg" alt="Esteban"></div>
            <div class="paragraph-1" data-hover="Nacida de padre afroamericano y madre en parte hispana, Spalding se siente cómoda con su identidad como afrolatina. De niña prodigio, comenzó a aprender y tocar instrumentos a la temprana edad de cinco años. Desde entonces, se ha convertido en una hábil intérprete de guitarra, bajo, violín, clarinete, oboe y algunos otros instrumentos."><p>Born to an African-American father and part-Hispanic mother, Spalding is comfortable with her identity as a Afro-Latina. As a child prodigy, she began learning and playing instruments at the young age of five years old. Since then, she has become a proficient player of the guitar, bass, violin, clarinet, oboe, and a few other instruments.</p></div>
            <div class="paragraph-1" data-hover="Spalding es mejor conocida por su trabajo como bajista y cantante de jazz. Durante su carrera, ha ganado varios premios Grammy por su música de jazz. Es reconocida como el primer músico de jazz en ganar un Grammy en la categoría de Mejor Artista Nuevo."><p>Spalding is best known for her work as a jazz bassist and singer. During her career, she has earned multiple Grammys for her jazz music. She is recognized as the first jazz musician to win a Grammy in the Best New Artist category.</p></div>
            <h2 class="pb-5 mt-5">Piri Thomas</h2>
            <div class="images text-center pb-5"><img src="@/assets/PiriThomas.jpg" alt="Esteban"></div>
            <div class="paragraph-1 pb-3" data-hover="Piri Thomas es ampliamente conocido por su obra principal 'Down These Mean Streets', una memoria que documenta su experiencia siendo cubano y puertorriqueño en el barrio de Spanish Harlem en la ciudad de Nueva York. En esta pieza, se enfrenta a muchos de los problemas que enfrentó con autenticidad y profundidad. Esto le brindó una idea de los latinos negros de su comunidad y las luchas que enfrentaban."><p>Piri Thomas is widely known for his title work “Down These Mean Streets,” a memoir that documents his experience being Cuban and Puerto Rican in the neighborhood of Spanish Harlem in New York City. In this piece, he confronts a lot of the issues he faced authentically and in-depth. This provided him with insight on the Black Latinos of his community and the struggles that they dealt with.</p></div>
            <div class="paragraph-1" data-hover="Más adelante en su vida, Thomas continuó escribiendo e interactuando con los jóvenes en riesgo de su comunidad. Su legado también incluye desempeñar un papel importante en el Movimiento Nuyorican, donde él, junto con otros artistas, poetas y escritores puertorriqueños, luchó para infundir orgullo en los puertorriqueños de la ciudad de Nueva York. Tenía 83 años cuando murió en 2011."><p>Later on his life, Thomas continued to write and engage with the at-risk youth of his community. His legacy also includes playing a large role in the Nuyorican Movement, where he, along with other Puerto Rican artists, poets, and writers, fought to instill pride in the Puerto Ricans of New York City. He was 83 when he died in 2011.</p></div>
            <h2 class="pb-5 mt-5">Mariano Rivera</h2>
            <div class="images text-center pb-5"><img src="@/assets/marianorivera.jpg" alt="Esteban"></div>
            <div class="paragraph-1 pb-3" data-hover="Popularmente conocido como 'Mo' o 'Sandman' durante su carrera en el béisbol, Mariano Rivera es un afrolatino de la ciudad de Panamá, Panamá."><p>Popularly known as “Mo” or “Sandman” during his baseball career, Mariano Rivera is an Afro-Latino from Panama City, Panama.</p></div>
            <div class="paragraph-1" data-hover="Como relevista de los Yankees de Nueva York, se hizo famoso por sus impresionantes estadísticas de pitcheo. Durante su tiempo con la Major League Baseball (MLB), Rivera pasó a la historia como líder en salvamentos y juegos terminados. Jugó durante un total de 19 temporadas, fue All-Star 13 veces y ganó la Serie Mundial cinco veces."><p>As a reliever for the New York Yankees, he became famous for his impressive pitching statistics. During his time with the Major League Baseball (MLB), Rivera went down in history as a leader in saves and games finished. He played for a total of 19 seasons, was an All-Star 13 times, and won the World Series five times.</p></div>
            <div class="paragraph-1" data-hover="En 2019, Rivera fue incluido en el Salón de la Fama del Béisbol y se convirtió en el primer jugador en la industria del salón en ser elegido por unanimidad."><p>In 2019, Rivera was inducted into the Baseball Hall of Fame and became the first player in the hall’s industry to be elected unanimously.</p></div>

        </div>
        <div class="col-xs-12 col-md-6">

        </div>
          
          
      </div>
      
  </v-container>
  
</template>

<script>

export default {
  name: 'Black-History-Month',
  
}
</script>

<style scoped>
.home-container{
    background-color: #D9AFD9;
    background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%); 
  }
.titulo{
    font-size: 60px;
    color: #e077d9;
    -webkit-text-fill-color:  #e077d9; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #4f28a3;
}
.subtitulo{
    padding-top: 20px;
    color: deeppink;
}
.images img{
  max-width: 500px;
  box-shadow: 10px 10px 20px 0px;
}
.title-1 h1{
    transition: all 600ms;
}
.paragraph-1 p{
    transition: all 600ms;
}
.paragraph-1::before{
    content: attr(data-hover);
    opacity: 0;
    position: absolute;
    transition: all 600ms;
    width: 70%;
    color:#4f28a3;
}

.title-1::before{
    font-size: 2em;
    font-weight: bold;
    width: 70%;
    content: attr(data-hover);
    opacity: 0;
    position: absolute;
    transition: all 600ms;
    color:#4f28a3;
}

.title-1:hover h1{
    opacity: 0;
}
.paragraph-1:hover p{
    opacity: 0;
}

.paragraph-1:hover:before{
    opacity: 1;
}
.title-1:hover:before{
    opacity: 1;
}
.vid{
  padding-bottom:3rem;
}
h2{
    text-align: center;
    font-size: xxx-large;
}
@media all and (max-width: 959px){
  .titulo{
           font-size: 40px; 
        }
   .vid{
     width: 350px !important;
     height: 196px !important;
   }   
}
</style>
