<template>
  <v-container class="home-container" fluid>
        <div class="text-center">
            <h1 class="titulo">EL ABECEDARIO</h1>
        </div>
        <div class="text-center font-italic">
            <h2>The alphabet</h2>
        </div>
        <div class="text-center">
            <h3>Presiona la letra y luego la imagen</h3>
            <h4 class="font-italic">(Click on letter and then on image)</h4>
        </div>
    <v-container>
        <v-row>
            <v-col cols="4" md="2">
                <div>
                    <div class="text-center">
                        <v-btn @click="letras(0)" x-large class="success my-3" >A</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                    <v-btn @click="letras(1)" x-large class="my-3" >B</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                        <v-btn  @click="letras(2)" x-large class="success my-3" >C</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                        <v-btn  @click="letras(3)" x-large class="my-3" >D</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                        <v-btn @click="letras(4)" x-large class="success my-3" >E</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                    <v-btn @click="letras(5)" x-large class="my-3" >F</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                        <v-btn @click="letras(6)" x-large class="success my-3" >G</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                    <v-btn @click="letras(7)" x-large class="my-3" >H</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                        <v-btn @click="letras(8)" x-large class="success my-3" >I</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                    <v-btn @click="letras(9)" x-large class="my-3" >J</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                        <v-btn @click="letras(10)" x-large class="success my-3" >K</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                    <v-btn @click="letras(11)" x-large class="my-3" >L</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                    <v-btn @click="letras(12)" x-large class="success my-3" >M</v-btn>
                    </div>
                </div>
                
            </v-col>  
            <v-col class="contenido" cols="8">
                <div class="text-center mt-5">
                    <h1>{{(palabra[index].Aspa)}}</h1>
                    <h2 class="font-italic">{{palabra[index].Aeng}}</h2>
                </div>
                <div class="text-center mt-5">     
                    <transition  
                    mode="out-in"
                    enter-active-class="animate__animated animate__fadeIn"
                    leave-active-class="animate__animated animate__fadeOut">
                    <img v-if="image" :key="image.id" @click="playAudio()" class="image imagenAbecedario" :src="image.src" alt="image.alt">
                    </transition>
                </div>
            </v-col>
            <v-col cols="4" md="2">
                <div>
                    <div class="text-center">
                    <v-btn @click="letras(13)" x-large class="success my-3" >N</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                    <v-btn @click="letras(14)" x-large class="my-3" >Ñ</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                        <v-btn @click="letras(15)" x-large class="success my-3" >O</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                    <v-btn @click="letras(16)" x-large class="my-3" >P</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                        <v-btn @click="letras(17)" x-large class="success my-3" >Q</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                    <v-btn @click="letras(18)" x-large class="my-3" >R</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                        <v-btn @click="letras(19)" x-large class="success my-3" >S</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                    <v-btn @click="letras(20)" x-large class="my-3" >T</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                        <v-btn @click="letras(21)" x-large class="success my-3" >U</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                    <v-btn @click="letras(22)" x-large class="my-3" >V</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                        <v-btn @click="letras(23)" x-large class="success my-3" >W</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                    <v-btn @click="letras(24)" x-large class="my-3" >X</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                        <v-btn @click="letras(25)" x-large class="success my-3" >Y</v-btn>
                    </div>
                </div>
                <div>
                    <div class="text-center">
                    <v-btn @click="letras(26)" x-large class="my-3" >Z</v-btn>
                    </div>
                </div>
            </v-col> 
            <v-col id="content2" class="contenido" cols="8">
                <div class="text-center mt-5">
                    <h1>{{(palabra[index].Aspa)}}</h1>
                    <h2 class="font-italic">{{palabra[index].Aeng}}</h2>
                </div>
                <div class="text-center mt-5">     
                    <transition  
                    mode="out-in"
                    enter-active-class="animate__animated animate__fadeIn"
                    leave-active-class="animate__animated animate__fadeOut">
                    <img v-if="image" :key="image.id" @click="playAudio()" class="image imagenAbecedario" :src="image.src" alt="image.alt">
                    </transition>
                </div>
            </v-col>

        </v-row>
        <v-row>
            <v-col cols="12" class="text-center pt-16">
                <div>
                    <h1>Aprende las letras jugando!</h1>
                    <h2 class="font-italic">Learn the letters playing</h2>
                </div>
                <div class="pt-10">
                    <v-btn x-large href="https://arbolabc.com/juegos-del-abecedario/memoria" target="_blank">Jugar(Play)</v-btn>
                </div>
            </v-col>
            
        </v-row>
    </v-container>  
  </v-container>
  
</template>

<script>
export default {
  name: 'Abecedario',
  
  data() {
    return {
      index: 0,
      image: null,
      audioSrc: '',
      images: [{
          id: 1,
          src: require('@/assets/ardilla.jpg'),
          alt: "Ardilla"
        },
        {
          id: 2,
          src: "https://picsum.photos/id/1066/400/400",
          alt: "Bebé"
        },
        {
          id: 3,
          src: require('@/assets/conejo.jpg'),
          alt: "Conejo"
        },
        {
          id: 4,
          src: require('@/assets/dedos.jpg'),
          alt: "Dedos"
        },
        {
          id: 5,
          src: "https://picsum.photos/id/818/400/400",
          alt: "Estatua"
        },
        {
          id: 6,
          src: "https://picsum.photos/id/1080/400/400",
          alt: "F"
        },
        {
          id: 7,
          src: require('@/assets/galletas.png'),
          alt: "Galletas"
        },
        {
          id: 8,
          src: require('@/assets/hilo.jpg'),
          alt: "Hilo"
        },
        {
          id: 9,
          src: require('@/assets/iglesia.jpg'),
          alt: "Iglesia"
        },
        {
          id: 10,
          src: require('@/assets/jirafa.jpg'),
          alt: "Jirafa"
        },
        {
          id:11,
          src: require('@/assets/koala.jpg'),
          alt: "Koala"
        },
        {
          id: 12,
          src: require('@/assets/leon.jpg'),
          alt: "León"
        },
        {
          id: 13,
          src: "https://picsum.photos/id/783/400/400",
          alt: "Mono"
        },
        {
          id: 14,
          src: "https://picsum.photos/id/841/400/400",
          alt: "Nadar"
        },
        {
          id: 15,
          src: require('@/assets/ñandu.jpg'),
          alt: "Ñandu"
        },
        {
          id: 16,
          src: require('@/assets/oso.jpg'),
          alt: "Oso"
        },
        {
          id: 17,
          src: "https://picsum.photos/id/1062/400/400",
          alt: "Perro"
        },
        {
          id: 18,
          src: require('@/assets/queso.jpg'),
          alt: "Queso"
        },
        {
          id: 19,
          src: require('@/assets/rosa.jpg'),
          alt: "Rosa"
        },
        {
          id: 20,
          src: require('@/assets/serpiente.jpg'),
          alt: "Serpiente"
        },
        {
          id: 21,
          src: "https://picsum.photos/id/23/400/400",
          alt: "Tenedor"
        },
        {
          id: 22,
          src: "https://picsum.photos/id/674/400/400",
          alt: "Uvas"
        },
        {
          id: 23,
          src: require('@/assets/vaca.jpg'),
          alt: "Vaca"
        },
        {
          id: 24,
          src: require('@/assets/wafles.jpg'),
          alt: "Wafles"
        },
        {
          id: 25,
          src: require('@/assets/xilofono.jpg'),
          alt: "Xilófono"
        },
        {
          id:26,
          src: require('@/assets/yogur.jpg'),
          alt: "Yogur"
        },
        {
          id: 27,
          src: require('@/assets/zorro.jpg'),
          alt: "Zorro"
        }
      ],
      palabra: [{
          Aspa: 'Ardilla',
          Aeng: 'Chipmunk'
      },
      {
          Aspa: 'Bebé',
          Aeng: 'Baby'
      },
      {
          Aspa: 'Conejo',
          Aeng: 'Rabbit'
      },
      {
          Aspa: 'Dedos',
          Aeng: 'Fingers'
      },
      {
          Aspa: 'Estatua',
          Aeng: 'Statue'
      },
      {
          Aspa: 'Fresas',
          Aeng: 'Strawberries'
      },
      {
          Aspa: 'Galletas',
          Aeng: 'Cookies'
      },
      {
          Aspa: 'Hilo',
          Aeng: 'Thread'
      },
      {
          Aspa: 'Iglesia',
          Aeng: 'Church'
      },
      {
          Aspa: 'Jirafa',
          Aeng: 'Giraffe'
      },
      {
          Aspa: 'Koala',
          Aeng: 'Koala'
      },
      {
          Aspa: 'León',
          Aeng: 'Lion'
      },
      {
          Aspa: 'Mono',
          Aeng: 'Monkey'
      },
      {
          Aspa: 'Nadar',
          Aeng: 'Swim'
      },
      {
          Aspa: 'Ñandú',
          Aeng: 'Rhea'
      },
      {
          Aspa: 'Oso',
          Aeng: 'Bear'
      },
      {
          Aspa: 'Perro',
          Aeng: 'Dog'
      },
      {
          Aspa: 'Queso',
          Aeng: 'Cheese'
      },
      {
          Aspa: 'Rosa',
          Aeng: 'Rose'
      },
      {
          Aspa: 'Serpiente',
          Aeng: 'Snake'
      },
      {
          Aspa: 'Tenedor',
          Aeng: 'Fork'
      },
      {
          Aspa: 'Uvas',
          Aeng: 'Grapes'
      },
      {
          Aspa: 'Vaca',
          Aeng: 'Cow'
      },
      {
          Aspa: 'Wafles',
          Aeng: 'Waffles'
      },
      {
          Aspa: 'Xilófono',
          Aeng: 'Xylophone'
      },
      {
          Aspa: 'Yogur', 
          Aeng: 'Yogurt'
      },
      {
          Aspa: 'Zorro',
          Aeng: 'Fox'
      }],

    }
  },
  mounted() {
    this.letras(0);
  },
  methods: {
    letras(i) {
      this.image = this.images[i];
      this.index = i;
          if(i > 20 || (i>7 && i<13)){
          document.getElementsByClassName('contenido')[0].style.alignSelf = "flex-end";
          document.getElementsByClassName('contenido')[1].style.alignSelf = "flex-end";
          }else{
            document.getElementsByClassName('contenido')[0].style.alignSelf = "flex-start";
            document.getElementsByClassName('contenido')[1].style.alignSelf = "flex-start";
          }
      
      
    },
    playAudio() {
        if (this.index === 0){
            var audio = new Audio(require('@/assets/ardilla.mp3'));
            audio.play();
        }else if(this.index === 1){
            var audio = new Audio(require('@/assets/bebe.mp3'));
            audio.play();
        }else if(this.index === 2){
            var audio = new Audio(require('@/assets/conejo.mp3'));
            audio.play();
        }else if(this.index === 3){
            var audio = new Audio(require('@/assets/dedos.mp3'));
            audio.play();
        }else if(this.index === 4){
            var audio = new Audio(require('@/assets/estatua.mp3'));
            audio.play();
        }else if(this.index === 5){
            var audio = new Audio(require('@/assets/fresas.mp3'));
            audio.play();
        }else if(this.index === 6){
            var audio = new Audio(require('@/assets/galletas.mp3'));
            audio.play();
        }else if(this.index === 7){
            var audio = new Audio(require('@/assets/hilo.mp3'));
            audio.play();
        }else if(this.index === 8){
            var audio = new Audio(require('@/assets/iglesia.mp3'));
            audio.play();
        }else if(this.index === 9){
            var audio = new Audio(require('@/assets/jirafa.mp3'));
            audio.play();
        }else if(this.index === 10){
            var audio = new Audio(require('@/assets/koala.mp3'));
            audio.play();
        }else if(this.index === 11){
            var audio = new Audio(require('@/assets/leon.mp3'));
            audio.play();
        }else if(this.index === 12){
            var audio = new Audio(require('@/assets/mono.mp3'));
            audio.play();
        }else if(this.index === 13){
            var audio = new Audio(require('@/assets/nadar.mp3'));
            audio.play();
        }else if(this.index === 14){
            var audio = new Audio(require('@/assets/ñandu.mp3'));
            audio.play();
        }else if(this.index === 15){
            var audio = new Audio(require('@/assets/oso.mp3'));
            audio.play();
        }else if(this.index === 16){
            var audio = new Audio(require('@/assets/perro.mp3'));
            audio.play();
        }else if(this.index === 17){
            var audio = new Audio(require('@/assets/queso.mp3'));
            audio.play();
        }else if(this.index === 18){
            var audio = new Audio(require('@/assets/rosa.mp3'));
            audio.play();
        }else if(this.index === 19){
            var audio = new Audio(require('@/assets/serpiente.mp3'));
            audio.play();
        }else if(this.index === 20){
            var audio = new Audio(require('@/assets/tenedor.mp3'));
            audio.play();
        }else if(this.index === 21){
            var audio = new Audio(require('@/assets/uvas.mp3'));
            audio.play();
        }else if(this.index === 22){
            var audio = new Audio(require('@/assets/vaca.mp3'));
            audio.play();
        }else if(this.index === 23){
            var audio = new Audio(require('@/assets/wafles.mp3'));
            audio.play();
        }else if(this.index === 24){
            var audio = new Audio(require('@/assets/xilofono.mp3'));
            audio.play();
        }else if(this.index === 25){
            var audio = new Audio(require('@/assets/yogur.mp3'));
            audio.play();
        }else if(this.index === 26){
            var audio = new Audio(require('@/assets/zorro.mp3'));
            audio.play();
        }
    }
  },
  components: {
      
  }
}
</script>

<style scoped>
    .home-container{
        background-color: #D9AFD9;
        background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%); 
    }
    .imagenAbecedario{
        align-self:flex-start;
        border-radius: 50%;
        box-shadow: 5px 10px 18px #888888;
    }  
    .image {
        width: 500px;
        height: 500px;
        margin: 2px;
        cursor: pointer;
        transition: filter 0.3s ease-in;
    }
    .titulo{
        font-size: 60px;
        color: #e077d9;
        -webkit-text-fill-color:  #e077d9; /* Will override color (regardless of order) */
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: #4f28a3;
    }
    @media all and (min-width: 960px) {
        #content2{
            display: none;
        }
    }
    @media all and (max-width: 959px) {
        .imagenAbecedario{
            height: 300px;
            width: 300px;
        }
        .titulo{
           font-size: 40px; 
        }
    }
    @media all and (max-width: 600px) {
        .imagenAbecedario{
            height: 200px;
            width: 200px;
        }
    }
</style>
