<template>
  <v-container class="home-container" fluid>
      <div class="p-5 text-center">
          <h1 class="titulo">GAMES</h1>
          <h1>Los números</h1>
          <h2 class="font-italic">The numbers</h2>
          <div class="pt-10">
              <a href="https://rockalingua.com/games/numbers" target="_blank"><img class="juego1" src="@/assets/numeros.jpg" alt="Numbers Game"></a>
          </div>
          
      </div>
      
  </v-container>
  
</template>

<script>
export default {
  name: 'Games',
  
  
}
</script>

<style scoped>
.home-container{
    background-color: #D9AFD9;
    background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%); 
  }
.titulo{
      font-size: 60px;
      color: #e077d9;
      -webkit-text-fill-color:  #e077d9; /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #4f28a3;
  }
  @media all and (max-width: 959px){
  .titulo{
           font-size: 40px; 
  }
  .juego1{
    width: 350px;
  }
  }
</style>
