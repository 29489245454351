<template>
  <v-container class="home-container" fluid>
      <div class="p-5 text-center">
          <h1 class="titulo">LAS PARTES DEL CUERPO</h1>
          <h2 class="font-italic">The parts of the body</h2>
          <div class="d-flex elementsDist">
              <div class="btn-back hide">
                <button @click="back()">Back</button>
              </div>
              <div class="pt-10 text-center cuerpo">
                <div class="layout">
                  <img class = "fade-in fade-out" src="@/assets/cuerpo.png" alt="Partes del cuerpo">
                  <h1 class="titles">This is our body</h1>
                </div>
                
              </div>
              <div class="pt-10 text-center cuerpo2 no-show">
                  <img class = "fade-in fade-out" src="@/assets/cuerpo.png" alt="Partes del cuerpo">
              </div>
              <div class="pt-10 text-center cuerpo3 no-show">
                  <img class = "fade-in fade-out" src="@/assets/cuerpo.png" alt="Partes del cuerpo">
              </div>
              <div class="btn-next">
                <button @click="next()">Next</button>
              </div>
          </div>
          
          
      </div>
      
  </v-container>
  
</template>

<script>
export default {
  name: 'Body',
  data(){
    return{
      cont: 0,
    }
  },
  methods:{
      next(){
          this.cont++;
          switch(this.cont){
              case 1:
                $('.cuerpo').addClass('no-show');
                $('.cuerpo2').removeClass('no-show');  
                $('.btn-back').removeClass('hide'); 
                  break;
              case 2:
                $('.cuerpo2').addClass('no-show');
                $('.cuerpo3').removeClass('no-show');  
                  break;
              default:
                  break;
          } 
          
      },
      back(){
          this.cont--;
          switch(this.cont){
              case 0:
                $('.cuerpo2').addClass('no-show');
                $('.cuerpo').removeClass('no-show');  
                $('.btn-back').addClass('hide'); 
                  break;
              case 1:
                $('.cuerpo2').removeClass('no-show');  
                $('.cuerpo3').addClass('no-show');
                  break;
              default:
                  break;
          } 
          
      }
  }
}
</script>

<style scoped>
.home-container{
    background-color: #D9AFD9;
    background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%); 
  }
.titulo{
      font-size: 60px;
      color: #e077d9;
      -webkit-text-fill-color:  #e077d9; /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: #4f28a3;
  }

.elementsDist{
    justify-content: space-evenly;
}

.cuerpo{
    z-index: 0;
}
.cuerpo2{
    z-index: 1;
}

.btn-back{
    position: relative;
    top:50px;
    left:50px;
}
.btn-next{
    position: relative;
    top:50px;
    right:100px;
}

.no-show{
    display: none;
}

.hide{
  visibility: hidden;
}

.titles{
  font-family: 'Indie Flower', cursive;
  position:relative;
  top:100px;
}


.fade-in {
animation: fadeIn ease-in-out 3s;
-webkit-animation: fadeIn ease-in-out 3s;
-moz-animation: fadeIn ease-in-out 3s;
-o-animation: fadeIn ease-in-out 3s;
-ms-animation: fadeIn ease-in-out 3s;
}
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}


</style>
