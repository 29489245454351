<template>
  <v-container class="home-container" fluid>
      <div class="p-5 text-center">
          <h1 class="titulo">MUSIC VIDEOS</h1>
          <h1>Canciones para aprender español</h1>
          <h2 class="font-italic">Songs to learn spanish</h2>
          <h2 class="subtitulo">Learn the colors!</h2>
          <div class="pt-3">
            <iframe class="vid" width="560" height="315" src="https://www.youtube.com/embed/gBeYkZiaSB8?controls=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>  
          </div>
          <h2 class="subtitulo">7 days of week</h2>
          <div class="pt-3">
            <iframe class="vid" width="560" height="315" src="https://www.youtube.com/embed/SQbspMMdJU8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div> 
          <h2 class="subtitulo">Spanish months of the year</h2>
          <div class="pt-3">
            <iframe class="vid" width="560" height="315" src="https://www.youtube.com/embed/HK9jD3gWhxU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>      
          </div> 
          <h2 class="subtitulo">Spanish greetings</h2>
          <div class="pt-3">
            <iframe class="vid" width="560" height="315" src="https://www.youtube.com/embed/bDGNBkVHGrQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          
      </div>
      
  </v-container>
  
</template>

<script>
export default {
  name: 'Music-Videos',
  
  
}
</script>

<style scoped>
.home-container{
    background-color: #D9AFD9;
    background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%); 
  }
.titulo{
    font-size: 60px;
    color: #e077d9;
    -webkit-text-fill-color:  #e077d9; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #4f28a3;
}
.subtitulo{
    padding-top: 20px;
    color: deeppink;
}
@media all and (max-width: 959px){
  .titulo{
           font-size: 40px; 
        }
   .vid{
     width: 350px !important;
     height: 196px !important;
   }   
}
</style>
